var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BModal', {
    attrs: {
      "id": "modal-edit-ctcm-ctce-for-1g-modal",
      "title": _vm.$t('reservation.editCtcmCtceFor1g.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex-center gap-2"
        }, [_c('BButton', {
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('BButton', {
          attrs: {
            "variant": _vm.addOperation ? 'info' : 'danger',
            "disabled": _vm.loading || (_vm.addOperation ? _vm.isDisabledAdd : _vm.isDisabledRemove)
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_vm._v(" " + _vm._s(_vm.addOperation ? _vm.$t('reservation.editCtcmCtceFor1g.addTitle') : _vm.$t('reservation.editCtcmCtceFor1g.removeTitle')) + " ")])], 1)];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_vm.bookingCode ? _c('span', [_vm._v(" Mã đặt chỗ: "), _c('span', {
    staticClass: "font-weight-bolder text-info font-medium-5"
  }, [_vm._v(_vm._s(_vm.bookingCode))])]) : _vm._e(), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-3 custom-radio"
        }, [_c('b-form-radio', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "add-radio",
            "value": "ADD"
          },
          model: {
            value: _vm.typeOperation,
            callback: function callback($$v) {
              _vm.typeOperation = $$v;
            },
            expression: "typeOperation"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder font-medium-2 mt-0"
        }, [_vm._v(_vm._s(_vm.$t('reservation.editCtcmCtceFor1g.addTitle')))])]), _c('b-form-radio', {
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "delete-radio",
            "value": "DELETE"
          },
          model: {
            value: _vm.typeOperation,
            callback: function callback($$v) {
              _vm.typeOperation = $$v;
            },
            expression: "typeOperation"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder font-medium-2 mt-0"
        }, [_vm._v(_vm._s(_vm.$t('reservation.editCtcmCtceFor1g.removeTitle')))])])], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "text-warning font-italic my-50"
  }, [_vm.addOperation ? _c('div', [_c('div', [_vm._v("(*) Lựa chọn hành khách để thêm CTCE/CTCM")]), _c('div', [_vm._v("(**) vui lòng không nhập thông tin khống")])]) : _c('div', [_c('div', [_vm._v("(*) Lựa chọn dòng để xoá")])]), _c('div', [_vm._v("(***) bạn sẽ chịu trách nhiệm bồi thường nếu thông tin sai")])]), _vm.addOperation ? _c('div', {
    staticClass: "my-1"
  }, _vm._l(_vm.paxData, function (pax, paxIndex) {
    return _c('div', {
      key: paxIndex
    }, [_c('BFormCheckbox', {
      model: {
        value: pax.isCheck,
        callback: function callback($$v) {
          _vm.$set(pax, "isCheck", $$v);
        },
        expression: "pax.isCheck"
      }
    }, [_c('div', {
      staticClass: "font-weight-bolder font-medium-1"
    }, [_vm._v(" " + _vm._s(pax.lastName) + " " + _vm._s(pax.firstName) + " " + _vm._s(pax.title) + " (" + _vm._s(pax.paxId) + ") ")])]), pax.isCheck ? _c('div', {
      staticClass: "d-flex flex-column gap-2"
    }, [_c('div', {
      staticClass: "d-flex-center px-2"
    }, [_c('BInputGroup', {
      staticClass: "border rounded"
    }, [_c('BInputGroupPrepend', {
      staticClass: "d-flex-center",
      class: _vm.isMobileView ? 'flex-wrap' : '',
      staticStyle: {
        "width": "100px",
        "background-color": "#EFEFEF"
      }
    }, [_c('BFormCheckbox', {
      model: {
        value: pax.isEmailCheck,
        callback: function callback($$v) {
          _vm.$set(pax, "isEmailCheck", $$v);
        },
        expression: "pax.isEmailCheck"
      }
    }, [_c('div', {
      staticClass: "font-weight-bolder font-medium-1 ml-50"
    }, [_vm._v(" CTCE ")])])], 1), _c('b-form-input', {
      class: _vm.isMobileView ? 'border-secondary' : '',
      staticStyle: {
        "min-width": "145px"
      },
      attrs: {
        "id": "ctce-".concat(pax.paxId),
        "disabled": !pax.isEmailCheck,
        "placeholder": "".concat(_vm.$t('reservation.editCtcmCtceFor1g.emailPlaceholder'))
      },
      model: {
        value: pax.email,
        callback: function callback($$v) {
          _vm.$set(pax, "email", $$v);
        },
        expression: "pax.email"
      }
    })], 1)], 1), _c('div', {
      staticClass: "d-flex-center px-2"
    }, [_c('BInputGroup', {
      staticClass: "border rounded"
    }, [_c('BInputGroupPrepend', {
      staticClass: "d-flex-center",
      class: _vm.isMobileView ? 'flex-wrap' : '',
      staticStyle: {
        "width": "100px",
        "background-color": "#EFEFEF"
      }
    }, [_c('BFormCheckbox', {
      model: {
        value: pax.isPhoneCheck,
        callback: function callback($$v) {
          _vm.$set(pax, "isPhoneCheck", $$v);
        },
        expression: "pax.isPhoneCheck"
      }
    }, [_c('div', {
      staticClass: "font-weight-bolder font-medium-1 ml-50"
    }, [_vm._v(" CTCM ")])])], 1), _c('b-form-input', {
      class: _vm.isMobileView ? 'border-secondary' : '',
      staticStyle: {
        "min-width": "145px"
      },
      attrs: {
        "id": "ctce-".concat(pax.paxId),
        "disabled": !pax.isPhoneCheck,
        "placeholder": "".concat(_vm.$t('reservation.editCtcmCtceFor1g.phonePlaceholder'))
      },
      model: {
        value: pax.phone,
        callback: function callback($$v) {
          _vm.$set(pax, "phone", $$v);
        },
        expression: "pax.phone"
      }
    })], 1)], 1)]) : _vm._e()], 1);
  }), 0) : _c('div', {
    staticClass: "my-1"
  }, _vm._l(_vm.ssrData, function (ssr, ssrIndex) {
    return _c('div', {
      key: ssrIndex
    }, [_c('BFormCheckbox', {
      model: {
        value: ssr.isCheck,
        callback: function callback($$v) {
          _vm.$set(ssr, "isCheck", $$v);
        },
        expression: "ssr.isCheck"
      }
    }, [_c('div', {
      staticClass: "font-weight-bolder font-medium-1"
    }, [_vm._v(" " + _vm._s(ssrIndex + 1) + " " + _vm._s(ssr.ssrType) + " " + _vm._s(ssr.airline) + " " + _vm._s(ssr.status) + " " + _vm._s(ssr.ssrText) + " ")])])], 1);
  }), 0), _vm.errorMessage ? _c('BAlert', {
    staticClass: "px-2 py-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }